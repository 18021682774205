import React from 'react';
import { InputLabel, FormControl, FormHelperText, Select } from '@material-ui/core';

const CategoriesSelect = ({ input, label, children, name, meta: { touched, error } }) => {
	return (
		<FormControl style={{ width: '100%' }} error={touched && error ? true : false}>
			<InputLabel htmlFor={name} shrink>
				{label}
			</InputLabel>
			<Select fullWidth displayEmpty {...input}>
				{children}
			</Select>
			{touched && error && <FormHelperText>{touched && error}</FormHelperText>}
		</FormControl>
	);
};

export default CategoriesSelect;
