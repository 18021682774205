import React from 'react';
import { connect } from 'react-redux';
import { fetchBanner, editUser } from '../actions';
import {
	Typography,
	withStyles,
	Paper,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	CircularProgress,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { TextField, Select } from 'redux-form-material-ui';
import { Save, Close } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import apiConfig from '../apiConfig';

const styles = theme => ({
	root: {
		padding: theme.spacing.unit,
	},
	button: {
		marginRight: theme.spacing.unit,
	},
	icon: {
		marginLeft: theme.spacing.unit,
	},
	paper: {
		padding: theme.spacing.unit,
	},
});

const Dropzone = props => {
	const {
		input: { onChange },
		label,
	} = props;
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop: files => onChange(files),
		accept: '.png,.jpg,.jpeg,.mp4',
		multiple: false,
	});

	const files = acceptedFiles.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	return (
		<div
			{...getRootProps()}
			style={{
				height: '10em',
				border: '1px solid',
				borderColor: 'rgba(0, 0, 0, 0.2)',
				padding: 8,
				marginBottom: 8,
				borderRadius: 8,
			}}
		>
			<input {...getInputProps()} />
			<p>{label} - Arrastrar y soltar o hacer click para seleccionar</p>
			<aside>
				<h4>Archivo:</h4>
				<ul>{files.length > 0 ? files : 'No se ha seleccionado ningún archivo'}</ul>
			</aside>
		</div>
	);
};

class UserEdit extends React.Component {
	componentDidMount() {
		this.props.fetchBanner(this.props.match.params.id);
	}

	submit = formProps => {
		let formData = new FormData();

		for (var key in formProps) {
			if (key === 'image' && Array.isArray(formProps['image'])) {
				formProps['image'].forEach(i => formData.append('image', i));
			} else if (key === 'mobile_image' && Array.isArray(formProps['mobile_image'])) {
				formProps['mobile_image'].forEach(i => formData.append('mobile_image', i));
			} else {
				formData.append(key, formProps[key]);
			}
		}

		apiConfig
			.patch(`/banners/${formProps._id}`, formData)
			.then(() => this.props.history.push(`/banners/position/${formProps.position}`));
	};

	required = value => (value == null ? 'Obligatorio' : undefined);

	url = value => {
		let response = undefined;
		const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

		if (!value) response = 'Obligatorio';
		else if (!regex.test(value)) response = 'No es una URL válida';

		return response;
	};

	render() {
		const { initialValues, classes, handleSubmit } = this.props;
		if (initialValues) {
			return (
				<div>
					<Typography variant="h5">Editar banner</Typography>
					<Paper className={classes.paper}>
						<form onSubmit={handleSubmit(this.submit)}>
							<Field
								name="title"
								component={TextField}
								label="Título"
								fullWidth
								required
								margin="normal"
								validate={this.required}
							/>

							<Field
								type="text"
								name="url"
								component={TextField}
								label="URL"
								helperText="Enlace del banner"
								fullWidth
								margin="normal"
								required
								validate={this.url}
							/>
							<FormControl fullWidth margin="normal">
								<InputLabel htmlFor="position">Posición</InputLabel>
								<Field
									name="position"
									component={Select}
									placeholder="Posición"
									fullWidth
									validate={this.required}
								>
									<MenuItem value="1">Superior</MenuItem>
									<MenuItem value="2">Superior izquierdo</MenuItem>
									<MenuItem value="3">Superior derecho</MenuItem>
									<MenuItem value="4">Inferior izquierdo</MenuItem>
									<MenuItem value="5">Inferior derecho</MenuItem>
								</Field>
							</FormControl>
							<Field name="image" component={Dropzone} label="Imagen" />
							<Field name="mobile_image" component={Dropzone} label="Imagen móvil" />
							<Button type="submit" variant="contained" color="primary">
								<Save />
								Guardar
							</Button>
							<Link to="/banners/position/1">
								<Button variant="contained" color="secondary">
									<Close />
									Cancelar
								</Button>
							</Link>
						</form>
					</Paper>
				</div>
			);
		}
		return <CircularProgress color="secondary" />;
	}
}

const mapStateToProps = state => ({
	initialValues: state.banners.banner,
});

let Form = reduxForm({ form: 'userEdit' })(UserEdit);
Form = connect(
	mapStateToProps,
	{ fetchBanner, editUser }
)(Form);
Form = withStyles(styles)(Form);
export default Form;
