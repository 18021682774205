import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createAdvert } from '../actions';

import AdvertForm from './AdvertForm';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		zIndex: 1,
		padding: theme.spacing.unit,
	},
	table: {
		minWidth: 700,
	},
});

class NewAdvert extends React.Component {
	send = values => {
		var form_data = new FormData();

		for (var key in values) {
			if (key === 'images') {
				values['images'].forEach(i => form_data.append('images', i));
			} else if (key === 'specific_fields') {
				const fields = JSON.stringify(values['specific_fields']);
				form_data.append(key, fields);
			} else {
				if (values[key]) {
					form_data.append(key, values[key]);
				}
			}
		}

		this.props.createAdvert(form_data, () => {
			this.props.history.push('/adverts');
		});
	};

	renderInitialValues = user => {
		let initial = {};

		if (user.email) initial['email'] = user.email;
		if (user.phone) initial['phone'] = user.phone;

		if (user.proffessional) initial['proffessional'] = 'profesional';
		else initial['proffessional'] = 'particular';

		initial['published'] = true;

		return initial;
	};

	render() {
		const { classes } = this.props;

		if (!this.props.user) {
			return <div>Cargando ...</div>;
		}

		return (
			<div>
				<Typography variant="h5">Nuevo Anuncio</Typography>
				<Paper className={classes.root}>
					<AdvertForm onConfirm={this.send} initialValues={this.renderInitialValues(this.props.user)} />
				</Paper>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return { user: state.auth.user };
};

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		{ createAdvert }
	)
)(NewAdvert);
