import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { fetchUser, deleteUser } from '../actions';
import {
	CircularProgress,
	Paper,
	Typography,
	withStyles,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TablePagination,
	Button,
	Snackbar,
	SnackbarContent,
} from '@material-ui/core';
import { Close, Check, Create, Delete, Autorenew } from '@material-ui/icons';
import DeleteModal from './DeleteModal.jsx';

import apiConfig from '../apiConfig';

const styles = theme => ({
	root: {
		padding: theme.spacing.unit,
	},
	paper: {
		padding: theme.spacing.unit,
		marginTop: theme.spacing.unit,
	},
	button: {
		marginRight: theme.spacing.unit,
		marginTop: theme.spacing.unit,
	},
	icon: {
		marginLeft: theme.spacing.unit,
	},
	success: {
		backgroundColor: '#28a745',
	},
	error: {
		backgroundColor: '#dc3545',
	},
});

class User extends React.Component {
	state = {
		page: 0,
		rowsPerPage: 10,
		pageLikes: 0,
		rowsPerPageLikes: 10,
		openModal: false,
		openSnackbar: false,
		error: false,
		message: '',
	};

	componentDidMount() {
		this.props.fetchUser(this.props.match.params.id);
	}

	renewAdverts() {
		apiConfig.patch(`/adverts/user/${this.props.user._id}/renew`).then(result => {
			if (result.data.ok === 1) {
				this.props.fetchUser(this.props.user.username);
				this.setState({
					openSnackbar: true,
					error: false,
					message:
						result.data.nModified > 1 ? result.data.nModified + ' anuncios renovados' : 'Anuncio renovado',
				});
			} else {
				this.setState({
					openSnackbar: true,
					error: true,
					message: 'Error en la renovación',
				});
			}
		});
	}

	render() {
		const { user, classes } = this.props;
		const { rowsPerPage, page, pageLikes, rowsPerPageLikes, openModal } = this.state;

		if (user)
			return (
				<div className={classes.root}>
					<Typography variant="h5">{user.name}</Typography>
					<Link to={`/user/${user.username}/edit`}>
						<Button color="primary" variant="contained" className={classes.button}>
							Modificar
							<Create className={classes.icon} />
						</Button>
					</Link>
					<Button
						color="secondary"
						variant="contained"
						className={classes.button}
						onClick={() => this.setState({ openModal: true })}
					>
						Eliminar
						<Delete className={classes.icon} />
					</Button>
					<Paper className={classes.paper}>
						<List>
							<ListItem>
								<ListItemText primary="ID" secondary={user._id} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Nombre de usuario" secondary={user.username} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Correo electrónico" secondary={user.email} />
							</ListItem>
							{user.phone && (
								<ListItem>
									<ListItemText primary="Teléfono" secondary={user.phone} />
								</ListItem>
							)}
							<ListItem>
								<ListItemText
									primary="Fecha de registro"
									secondary={new Date(user.register).toLocaleString()}
								/>
							</ListItem>
							<ListItem>
								<ListItemText
									primary="Tipo de usuario"
									secondary={user.proffessional ? 'Profesional' : 'Particular'}
								/>
							</ListItem>
							<ListItem>
								<ListItemText primary="Administrador" style={{ flex: 'none' }} />
								{user.is_admin ? (
									<ListItemIcon style={{ color: 'green' }}>
										<Check />
									</ListItemIcon>
								) : (
									<ListItemIcon style={{ color: 'red' }}>
										<Close />
									</ListItemIcon>
								)}
							</ListItem>
						</List>
					</Paper>
					{user.adverts.length > 0 ? (
						<Button
							color="secondary"
							variant="contained"
							className={classes.button}
							onClick={() => this.renewAdverts()}
						>
							Renovar Anuncios
							<Autorenew className={classes.icon} />
						</Button>
					) : (
						''
					)}
					<Paper className={classes.paper}>
						<Typography variant="h6">Anuncios</Typography>

						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>Título</TableCell>
									<TableCell align="right">Categoría</TableCell>
									<TableCell align="right">Fecha de publicación</TableCell>
									<TableCell align="right">Fecha de actualización</TableCell>
									<TableCell align="right">Publicado</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{user.adverts.length > 0 ? (
									user.adverts
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((advert, i) => (
											<TableRow key={i}>
												<TableCell component="th" scope="row">
													{advert.title}
												</TableCell>
												<TableCell align="right">{advert.category.name}</TableCell>
												<TableCell align="right">
													{new Date(advert.created_at).toLocaleString()}
												</TableCell>
												<TableCell align="right">
													{new Date(advert.updated_at).toLocaleString()}
												</TableCell>
												<TableCell align="right">
													{advert.published ? (
														<Check color="primary" />
													) : (
														<Close color="error" />
													)}
												</TableCell>
											</TableRow>
										))
								) : (
									<TableRow>
										<TableCell colSpan={5}>
											<Typography variant="body1" align="center">
												Este usuario no tiene anuncios
											</Typography>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25, 50]}
										colSpan={5}
										count={user.adverts.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage="Ver: "
										onChangePage={(e, page) => this.setState({ page })}
										onChangeRowsPerPage={event =>
											this.setState({ rowsPerPage: event.target.value })
										}
										labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</Paper>
					<Paper className={classes.paper}>
						<Typography variant="h6">Me gusta</Typography>

						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>Título</TableCell>
									<TableCell align="right">Categoría</TableCell>
									<TableCell align="right">Fecha de publicación</TableCell>
									<TableCell align="right">Fecha de actualización</TableCell>
									<TableCell align="right">Publicado</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{user.likes.length > 0 ? (
									user.likes
										.slice(
											pageLikes * rowsPerPageLikes,
											pageLikes * rowsPerPageLikes + rowsPerPageLikes
										)
										.map((advert, i) => (
											<TableRow key={i}>
												<TableCell component="th" scope="row">
													{advert.title}
												</TableCell>
												<TableCell align="right">{advert.category.name}</TableCell>
												<TableCell align="right">
													{new Date(advert.created_at).toLocaleString()}
												</TableCell>
												<TableCell align="right">
													{new Date(advert.updated_at).toLocaleString()}
												</TableCell>
												<TableCell align="right">
													{advert.published ? (
														<Check color="primary" />
													) : (
														<Close color="error" />
													)}
												</TableCell>
											</TableRow>
										))
								) : (
									<TableRow>
										<TableCell colSpan={5}>
											<Typography variant="body1" align="center">
												Este usuario no tiene me gusta
											</Typography>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25, 50]}
										colSpan={5}
										count={user.likes.length}
										rowsPerPage={rowsPerPageLikes}
										page={pageLikes}
										labelRowsPerPage="Ver: "
										onChangePage={(event, pageLikes) => {
											this.setState({ pageLikes });
										}}
										onChangeRowsPerPage={event =>
											this.setState({ rowsPerPageLikes: event.target.value })
										}
										labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</Paper>
					<DeleteModal
						open={openModal}
						handleClose={() => this.setState({ openModal: false })}
						message={`¿Desea eliminar al usuario ${user.name}? Esta acción no se puede deshacer.`}
						onConfirm={() => {
							this.setState({ openModal: false });
							this.props.deleteUser(user._id, () => this.props.history.push('/users'));
						}}
					/>
					<Snackbar
						open={this.state.openSnackbar}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						onClose={() => this.setState({ openSnackbar: false })}
					>
						<SnackbarContent
							className={this.state.error ? classes.error : classes.success}
							message={this.state.message}
						/>
					</Snackbar>
				</div>
			);

		return <CircularProgress color="secondary" />;
	}
}

const mapStateToProps = state => ({
	user: state.users.user,
});

export default compose(
	connect(
		mapStateToProps,
		{ fetchUser, deleteUser }
	),
	withStyles(styles)
)(User);
