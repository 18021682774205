export const AUTH_USER = 'auth_user';
export const AUTH_ERROR = 'auth_error';
export const FETCH_USERS = 'fetch_users';
export const DELETE_USER = 'delete_user';
export const FETCH_USER = 'fetch_user';
export const FILTER_USERS = 'filter_users';
export const EDIT_USER = 'edit_user';

export const FETCH_BANNERS = 'fetch_banners';
export const FETCH_BANNER = 'fetch_banner';
export const FILTER_BANNERS = 'filter_banners';
export const REORDER_BANNERS = 'reorder_banners';
export const UPDATE_ORDER = 'update_order';
export const FETCH_ADVERT = 'fetch_advert';
export const FETCH_ADVERTS = 'fetch_adverts';
export const CREATE_ADVERT = 'create_advert';
export const EDIT_ADVERT = 'edit_advert';
export const DELETE_ADVERT = 'delete_advert';
export const FETCH_CATEGORIES = 'fetch_categories';
export const FETCH_SUB_CATEGORIES = 'fetch_sub_categories';
export const CLEAR_SUB_CATEGORIES = 'clear_sub_categories';
export const FETCH_SPECIFIC_FIELDS = 'fetch_specific_fields';
export const CLEAR_SPECIFIC_FIELDS = 'clear_specific_fields';
export const FETCH_PROVINCES = 'fetch_provinces';
export const SEARCH_LOCATION = 'search_location';
