import React from 'react';
import { fetchBanner } from '../actions';
import { connect } from 'react-redux';
import { Paper, Typography, CircularProgress, withStyles, Button } from '@material-ui/core';
import { compose } from 'redux';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { Delete, Create } from '@material-ui/icons';
import DeleteModal from './DeleteModal.jsx';
import apiConfig from '../apiConfig';

const styles = theme => ({
	paper: {
		padding: theme.spacing.unit,
	},
});

class Banner extends React.Component {
	state = { showModal: false };

	componentDidMount() {
		this.props.fetchBanner(this.props.match.params.id);
	}

	delete = () => {
		const {
			banner: { _id, position },
		} = this.props;

		apiConfig
			.delete(`/banners/${_id}`)
			.then(() =>
				this.setState({ showModal: false }, () => this.props.history.push(`/banners/position/${position}`))
			);
	};

	renderBanner = mobile => {
		const { banner } = this.props;
		const image = mobile ? banner.mobile_image : banner.image;

		if (image.split('.').pop() === 'mp4') {
			return <ReactPlayer playing={true} muted={true} loop={true} url={[{ src: image, type: 'video/mp4' }]} />;
		}
		return <img src={image} alt={banner.title} />;
	};

	render() {
		const { banner, classes } = this.props;
		if (banner) {
			return (
				<div>
					<Paper className={classes.paper}>
						<Link to={`/banner/${banner._id}/edit`}>
							<Button variant="contained" color="primary">
								<Create />
								Modificar
							</Button>
						</Link>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => this.setState({ showModal: true })}
						>
							<Delete />
							Eliminar
						</Button>
						<Typography variant="h5">{banner.title}</Typography>
						<Typography variant="body1">
							URL:{' '}
							<a href={banner.url} target="_blank" rel="noopener noreferrer">
								{banner.url}
							</a>
						</Typography>
						<Typography variant="h6">Imagen</Typography>
						{this.renderBanner(false)}
						<Typography variant="h6">Imagen móvil</Typography>
						{this.renderBanner(true)}
					</Paper>
					<DeleteModal
						message={`¿Desea eliminar el banner ${banner.title}? Esta acción no se puede deshacer`}
						handleClose={() => this.setState({ showModal: false })}
						open={this.state.showModal}
						onConfirm={this.delete}
					/>
				</div>
			);
		}
		return <CircularProgress color="secondary" />;
	}
}

const mapStateToProps = state => ({
	banner: state.banners.banner,
});

export default compose(
	connect(
		mapStateToProps,
		{ fetchBanner }
	),
	withStyles(styles)
)(Banner);
