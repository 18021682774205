import React from 'react';
import ReactSelect from 'react-select';
import { InputLabel, FormControl, FormHelperText} from '@material-ui/core';

import _ from 'lodash';

export default class ProvincesSelect extends React.Component {

	changeProvinces = (label, provinces) => {
		if (provinces) {
			const index = _.findIndex(provinces, { value: '*' });

			if (index > 0) {
				return [{ label, value: '*' }];
			} else if (index === 0 && provinces.length > 1) {
				return _.drop(provinces);
			}
		}

		return provinces;
	};

	render() {
		const {
			input,
			label,
			data,
			name,
			all_options_label,
			meta: { touched, error },
		} = this.props;
		const all_options = { label: all_options_label, value: '*' };
		const options = _.flatMap(data, d => [{ label: d.name, value: d._id }]);

		return (
			<FormControl style={{ width: '100%' }} error={touched && error ? true : false}>
				<InputLabel htmlFor={name} shrink style={{ position: 'relative' }}>
					{label}
				</InputLabel>
				<ReactSelect
					{...this.props}
					isMulti
					placeholder="Seleccionar"
					{...input}
					value={input.value || []}
					options={[all_options, ...options]}
					onChange={values => input.onChange(this.changeProvinces(all_options_label, values))}
					onBlur={() => input.onBlur()}
				/>
				{touched && error && <FormHelperText>{touched && error}</FormHelperText>}
			</FormControl>
		);
	}
}
