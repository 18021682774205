import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Typography, withStyles, Paper, Button, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { TextField, Select } from 'redux-form-material-ui';
import { Link } from 'react-router-dom';
import { Save, Close } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import api from '../apiConfig';

const styles = theme => ({
	paper: {
		padding: theme.spacing.unit,
	},
	icon: { margin: theme.spacing.unit },
});

const Dropzone = props => {
	const {
		input: { onChange },
		label,
	} = props;
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop: files => onChange(files),
		accept: '.png,.jpg,.jpeg,.mp4',
		multiple: false,
	});

	const files = acceptedFiles.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	return (
		<div
			{...getRootProps()}
			style={{
				height: '10em',
				border: '1px solid',
				borderColor: 'rgba(0, 0, 0, 0.2)',
				padding: 8,
				marginBottom: 8,
				borderRadius: 8,
			}}
		>
			<input {...getInputProps()} />
			<p>{label} - Arrastrar y soltar o hacer click para seleccionar</p>
			<aside>
				<h4>Archivo:</h4>
				<ul>{files.length > 0 ? files : 'No se ha seleccionado ningún archivo'}</ul>
			</aside>
		</div>
	);
};

class Banners extends React.Component {
	submit = formProps => {
		let formData = new FormData();

		for (var key in formProps) {
			if (key === 'image') {
				formProps['image'].forEach(i => formData.append('image', i));
			} else if (key === 'mobile_image') {
				formProps['mobile_image'].forEach(i => formData.append('mobile_image', i));
			} else {
				formData.append(key, formProps[key]);
			}
		}

		api.post('/banners', formData).then(() => this.props.history.push(`/banners/position/${formProps.position}`));
	};

	required = value => (value == null ? 'Obligatorio' : undefined);

	url = value => {
		let response = undefined;
		const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

		if (!value) response = 'Obligatorio';
		else if (!regex.test(value)) response = 'No es una URL válida';

		return response;
	};

	render() {
		const { classes, handleSubmit } = this.props;
		return (
			<div>
				<Typography variant="h5">Nuevo banner</Typography>
				<Paper className={classes.paper}>
					<form onSubmit={handleSubmit(this.submit)}>
						<Field
							name="title"
							component={TextField}
							label="Título"
							fullWidth
							required
							margin="normal"
							validate={this.required}
						/>

						<Field
							type="text"
							name="url"
							component={TextField}
							label="URL"
							helperText="Enlace del banner"
							fullWidth
							margin="normal"
							required
							validate={this.url}
						/>
						<FormControl fullWidth margin="normal">
							<InputLabel htmlFor="position">Posición</InputLabel>
							<Field
								name="position"
								component={Select}
								placeholder="Posición"
								fullWidth
								validate={this.required}
							>
								<MenuItem value="1">Superior</MenuItem>
								<MenuItem value="2">Superior izquierdo</MenuItem>
								<MenuItem value="3">Superior derecho</MenuItem>
								<MenuItem value="4">Inferior izquierdo</MenuItem>
								<MenuItem value="5">Inferior derecho</MenuItem>
							</Field>
						</FormControl>
						<Field name="image" component={Dropzone} label="Imagen" validate={this.required} />
						<Field name="mobile_image" component={Dropzone} label="Imagen móvil" validate={this.required} />
						<Button type="submit" variant="contained" color="primary">
							<Save />
							Guardar
						</Button>
						<Link to="/banners/position/1">
							<Button variant="contained" color="secondary">
								<Close />
								Cancelar
							</Button>
						</Link>
					</form>
				</Paper>
			</div>
		);
	}
}

let BannerComponent = reduxForm({ form: 'createBanner' })(Banners);
BannerComponent = withStyles(styles)(BannerComponent);
export default BannerComponent;
