import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
	TableFooter,
	TablePagination,
	Tooltip,
	Grid,
	Button,
	Snackbar,
	SnackbarContent,
} from '@material-ui/core';
import { Delete, Check, Close, Edit, Add, Autorenew } from '@material-ui/icons';

import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchAdverts, deleteAdvert } from '../actions';

import DeleteModal from './DeleteModal';

import SearchForm from './SearchForm';

import apiConfig from '../apiConfig';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		zIndex: 1,
	},
	table: {
		minWidth: 700,
	},
	button: {
		margin: theme.spacing.unit,
		float: 'right',
	},
	success: {
		backgroundColor: '#28a745',
	},
	error: {
		backgroundColor: '#dc3545',
	},
});

class Adverts extends React.Component {
	state = {
		page: 0,
		rowsPerPage: 10,
		values: {},
		openModal: false,
		advertID: '',
		openSnackbar: false,
		error: false,
		message: '',
	};

	componentDidMount() {
		this.props.fetchAdverts();
	}

	openModal = (advertID, advertRef) => {
		this.setState({ openModal: true, advertID });
	};

	closeModal = () => {
		this.setState({ openModal: false, advertID: '' });
	};

	handleConfirm = () => {
		this.props.deleteAdvert(this.state.advertID, () =>
			this.props.fetchAdverts(this.state.rowsPerPage, this.state.page + 1)
		);
		this.setState({ openModal: false });
	};

	onChangeRowsPerPage = rowsPerPage => {
		this.setState({ rowsPerPage });
		this.props.fetchAdverts(rowsPerPage, this.state.page + 1, this.state.values);
	};

	handleChangePage = (event, page) => {
		this.setState({ page }, () =>
			this.props.fetchAdverts(this.state.rowsPerPage, this.state.page + 1, this.state.values)
		);
	};

	onSubmit = values => {
		this.setState({ values }, () =>
			this.props.fetchAdverts(this.state.rowsPerPage, this.state.page + 1, this.state.values)
		);
	};

	renewAdverts() {
		apiConfig.post(`/adverts/renewall`).then(result => {
			if (result.data.ok === 1) {
				this.props.fetchAdverts();
				this.setState({
					openSnackbar: true,
					error: false,
					message:
						result.data.nModified > 1 ? result.data.nModified + ' anuncios renovados' : 'Anuncio renovados',
				});
			} else {
				this.setState({
					openSnackbar: true,
					error: true,
					message: 'Error en la renovación',
				});
			}
		});
	}

	render() {
		const { classes, advertList, total } = this.props;
		const { page, rowsPerPage } = this.state;

		return (
			<React.Fragment>
				<Grid container spacing={8}>
					<Grid item xs={6} style={{ marginTop: '15px' }}>
						<Typography noWrap variant="h5">
							Anuncios
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Link to={'/advert/new'}>
							<Button variant="contained" color="primary" aria-label="Nuevo" className={classes.button}>
								<Add className={classes.icon} /> Nuevo Anuncio
							</Button>
						</Link>
						<Button
							color="secondary"
							variant="contained"
							className={classes.button}
							onClick={() => this.renewAdverts()}
						>
							Renovar Anuncios
							<Autorenew className={classes.icon} />
						</Button>
					</Grid>
				</Grid>
				<SearchForm onSubmit={this.onSubmit} />

				<Paper className={classes.root}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Ref.</TableCell>
								<TableCell align="left">Titulo</TableCell>
								<TableCell align="left">Fecha Modificación</TableCell>
								<TableCell align="left">Fecha Creación</TableCell>
								<TableCell align="left">Autor</TableCell>
								<TableCell align="left">Categoría</TableCell>
								<TableCell align="left">Provincias</TableCell>
								<TableCell align="left">Publicado</TableCell>
								<TableCell align="left">Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{advertList.length > 0 ? (
								advertList.map(advert => (
									<TableRow key={advert._id}>
										<TableCell component="th" scope="row">
											{advert.reference}
										</TableCell>
										<TableCell align="left">{advert.title}</TableCell>
										<TableCell align="left">
											{new Date(advert.updated_at).toLocaleString()}
										</TableCell>
										<TableCell align="left">
											{new Date(advert.created_at).toLocaleString()}
										</TableCell>
										<TableCell align="left">
											{advert.user && advert.user.username && (
												<Link to={`/user/${advert.user.username}`}>{advert.user.username}</Link>
											)}
										</TableCell>
										<TableCell align="left">{_.capitalize(advert.category.name)}</TableCell>
										<TableCell align="left">{_.size(advert.provinces)}</TableCell>
										<TableCell align="left">
											{advert.published ? <Check color="primary" /> : <Close color="error" />}
										</TableCell>
										<TableCell align="left">
											<div>
												<Link to={`/advert/edit/${advert.reference}`}>
													<Tooltip title="Editar">
														<IconButton>
															<Edit />
														</IconButton>
													</Tooltip>
												</Link>
												<Tooltip title="Borrar">
													<IconButton onClick={() => this.openModal(advert.reference)}>
														<Delete />
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={9}>No hay resultados</TableCell>
								</TableRow>
							)}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25, 50]}
									colSpan={9}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									labelRowsPerPage="Ver: "
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={event => this.onChangeRowsPerPage(event.target.value)}
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
								/>
							</TableRow>
						</TableFooter>
					</Table>
					<DeleteModal
						open={this.state.openModal}
						handleClose={this.closeModal}
						message={`¿Desea eliminar el anuncio #${this.state.advertID}? Esta acción no se puede deshacer`}
						onConfirm={this.handleConfirm}
					/>
					<Snackbar
						open={this.state.openSnackbar}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						onClose={() => this.setState({ openSnackbar: false })}
					>
						<SnackbarContent
							className={this.state.error ? classes.error : classes.success}
							message={this.state.message}
						/>
					</Snackbar>
				</Paper>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	advertList: state.adverts.advertList,
	hasNext: state.adverts.has_next,
	pages: state.adverts.pages,
	total: state.adverts.total,
});

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		{ fetchAdverts, deleteAdvert }
	)
)(Adverts);
