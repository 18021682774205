import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import {
	TextField,
	Button,
	withStyles,
	MenuItem,
	InputLabel,
	FormControl,
	Grid,
	Chip,
	FormHelperText,
	Fab,
	Switch,
	FormControlLabel,
	FormLabel,
	Radio,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { reduxForm, Field, FieldArray, formValueSelector, change } from 'redux-form';

import ProvincesSelect from './ProvincesSelect';
import CategoriesSelect from './CategoriesSelect';
import SpecificFields from './SpecificFields';

import { connect } from 'react-redux';
import {
	fetchCategories,
	fetchSubCategories,
	clearSubCategories,
	fetchSpecificFields,
	clearSpecificFields,
	fetchProvinces,
	searchLocations,
} from '../actions';

import _ from 'lodash';

import Dropzone from 'react-dropzone';

const styles = theme => ({
	root: {
		width: '100%',
		padding: theme.spacing.unit,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
	},
	details: {
		alignItems: 'center',
	},
	column: {
		flexBasis: '33.33%',
		padding: '4px',
	},
	thumbsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: theme.spacing.unit * 2,
	},
	thumb: {
		display: 'inline-flex',
		borderRadius: 2,
		border: '1px solid #eaeaea',
		marginBottom: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 100,
		height: 100,
		padding: 4,
		position: 'relative',
		boxSizing: 'border-box',
	},
	thumbInner: {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden',
	},
	img: {
		display: 'block',
		width: 'auto',
		height: '100%',
	},
	dropBox: {
		height: '10em',
		border: '1px solid',
		borderColor: 'rgba(0, 0, 0, 0.2)',
		padding: theme.spacing.unit,
		borderRadius: theme.spacing.unit,
	},
	fab: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
});

class AdvertForm extends React.Component {
	componentDidMount() {
		this.props.fetchCategories();
		this.props.fetchProvinces();

		if (this.props.initialValues.categories) {
			this.initialCategoryValues();
		}
	}

	async initialCategoryValues() {
		for (let key in this.props.initialValues.categories) {
			await this.props.fetchSubCategories(this.props.initialValues.categories[key].category);

			let cat = _(this.props.categoryList[key].results)
				.chain()
				.find({ _id: this.props.initialValues.categories[key].category })
				.value();

			let specificFields = this.props.specificFields.slice(0, key);

			specificFields.splice(key, 0, cat.specific_fields);

			await this.props.fetchSpecificFields(specificFields);
		}
	}

	onSubmit = formValues => {
		const {
			title,
			description,
			tags,
			phone,
			email,
			price,
			provinces_es,
			provinces_pt,
			provinces_and,
			location,
			images,
			published,
			proffessional,
		} = formValues;

		let provincesES = _.map(provinces_es, 'value');
		let provincesPT = _.map(provinces_pt, 'value');
		let provincesAND = _.map(provinces_and, 'value');

		if (provincesES[0] === '*') {
			provincesES = _.map(this.props.provincesES, '_id');
		}

		if (provincesPT[0] === '*') {
			provincesPT = _.map(this.props.provincesPT, '_id');
		}

		if (provincesAND[0] === '*') {
			provincesAND = _.map(this.props.provincesAND, '_id');
		}

		let provinces = [];
		provinces = [...provincesES, ...provincesPT, ...provincesAND];

		let categories = [];

		if (this.props.categoryList) {
			let i;
			for (i = 0; i < this.props.categoryList.length; i++) {
				if (formValues['category_' + i] !== '') categories.push(formValues['category_' + i]);
			}
		}

		let specific_fields = [];

		if (this.props.specificFields.length > 0) {
			this.props.specificFields.forEach(fields => {
				if (fields.length > 0) {
					fields.forEach(field => {
						if (formValues[field.name]) {
							field['selectedValue'] = formValues[field.name];
							specific_fields.push(field);
						}
					});
				}
			});
		}

		const values = {
			title,
			description,
			tags,
			phone,
			email,
			price,
			category: categories,
			provinces,
			specific_fields,
			location: location && location.value ? location.value : '',
			images: images || [],
			published,
			proffessional: proffessional === 'profesional',
		};

		this.props.onConfirm(values);
	};

	setTags = value => {
		if (value === ',') {
			return '';
		}

		if (value.indexOf(',') > -1) {
			if (!this.props.tags || this.props.tags.length <= 19) {
				let tags = value.trim().split(',');

				this.props.array.push('tags', tags[0]);
			}
			return '';
		} else {
			return value;
		}
	};

	async changeCategory(category, index) {
		let specificFields = this.props.specificFields.slice(0, index);

		let categoryList = this.props.categoryList.slice(0, index + 1);

		let i = 0;

		for (i = index; i < this.props.categoryList.length; i++) {
			this.props.dispatch(change('advertForm', 'category_' + i, ''));

			if (this.props.specificFields[i]) {
				this.props.specificFields[i].forEach(field => {
					this.props.dispatch(change('advertForm', field.name, ''));
				});
			}
		}

		await this.props.clearSubCategories(categoryList);

		if (category.target.value) {
			await this.props.fetchSubCategories(category.target.value);

			let cat = _(categoryList[index].results)
				.chain()
				.find({ _id: category.target.value })
				.value();

			specificFields.splice(index, 0, cat.specific_fields);
		}

		await this.props.fetchSpecificFields(specificFields);
	}

	renderTextField = ({
		input,
		label,
		type,
		inputProps,
		required = false,
		multiline = false,
		rows = 0,
		meta: { touched, error, invalid },
	}) => {
		return (
			<FormControl style={{ width: '100%' }}>
				<TextField
					inputProps={inputProps}
					{...input}
					label={label}
					type={type}
					error={touched && invalid}
					helperText={touched && error}
					required={required}
					multiline={multiline}
					rows={rows}
				/>
			</FormControl>
		);
	};

	loadOptions = query => {
		let options = [];

		return this.props
			.searchLocations(query)
			.then(() => {
				if (_.size(this.props.locations) > 0) {
					options = this.props.locations.map(data => ({ label: data.name, value: data._id }));
					return options;
				} else {
					return options;
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	renderLocation = ({ input, label, name }) => {
		return (
			<FormControl style={{ width: '100%' }}>
				<InputLabel htmlFor={name} shrink style={{ position: 'relative' }}>
					{label}
				</InputLabel>
				<AsyncSelect
					{...input}
					value={input.value || []}
					loadOptions={this.loadOptions}
					placeholder="Buscar..."
					noOptionsMessage={() => 'Sin resultados'}
					onBlur={() => input.onBlur()}
				/>
			</FormControl>
		);
	};

	renderTags = ({ fields }) => {
		return (
			<React.Fragment>
				{fields.map((tag, index) => (
					<Chip
						key={index}
						label={fields.get(index)}
						onDelete={() => fields.remove(index)}
						style={{ margin: 2 }}
					/>
				))}
			</React.Fragment>
		);
	};

	renderImagesUpload = field => {
		return (
			<FormControl style={{ width: '100%' }}>
				<InputLabel htmlFor={field.name} shrink style={{ position: 'relative' }}>
					{field.label}
				</InputLabel>
				<Dropzone onDrop={this.onDrop} accept=".png,.jpg,.jpeg" maxSize={5000000} name={field.name}>
					{({ getRootProps, getInputProps, isDragActive }) => {
						return (
							<div className={field.className} {...getRootProps()}>
								<input {...getInputProps()} />
								{isDragActive ? (
									<p>Soltar</p>
								) : (
									<p>Arrastra y suelta aquí para subir, o haz click para seleccionar</p>
								)}
							</div>
						);
					}}
				</Dropzone>
				<FormHelperText>Tamaño máximo 5MB por imagen. Máximo 15 imágenes.</FormHelperText>
			</FormControl>
		);
	};

	renderImages = ({ fields, classes }) => {
		return (
			<aside className={classes.thumbsContainer}>
				{fields.map((file, index) => (
					<div className={classes.thumb} key={index}>
						<div className={classes.thumbInner}>
							<img alt={fields.get(index).name} src={fields.get(index).preview} className={classes.img} />
						</div>
						<Fab
							className={classes.fab}
							color="secondary"
							size="small"
							onClick={() => this.deleteImage(index)}
						>
							<Close />
						</Fab>
					</div>
				))}
			</aside>
		);
	};

	renderSwitch = ({ input, label }) => {
		return <FormControlLabel control={<Switch {...input} checked={input.value} />} label={label} />;
	};

	renderRadio = ({ input, label }) => {
		return <FormControlLabel control={<Radio {...input} cheched={input.value} />} label={label} />;
	};

	onDrop = files => {
		files.forEach(file => {
			Object.assign(file, {
				preview: URL.createObjectURL(file),
			});

			if (file.size < 5000000 && _.size(this.props.images) < 15) this.props.array.push('images', file);
		});
	};

	deleteImage = index => {
		this.props.array.splice('images', index, 1);
	};

	render() {
		const { classes } = this.props;

		return (
			<form id="advertForm" onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Field
							name="title"
							label="Titulo"
							component={this.renderTextField}
							type="text"
							required
							inputProps={{
								maxLength: 150,
								minLength: 5,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							name="description"
							label="Descripción"
							component={this.renderTextField}
							type="text"
							rows={6}
							required
							inputProps={{
								maxLength: 5000,
								minLength: 5,
							}}
							multiline
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							name="tag"
							label="Palabras clave"
							component={this.renderTextField}
							type="text"
							normalize={this.setTags}
							inputProps={{
								maxLength: 30,
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<FieldArray name="tags" component={this.renderTags} />
					</Grid>
					<Grid item sm={4} xs={12}>
						<Field name="phone" label="Teléfono" component={this.renderTextField} type="tel" />
					</Grid>
					<Grid item sm={4} xs={12}>
						<Field name="email" label="Correo electrónico" component={this.renderTextField} type="email" />
					</Grid>
					<Grid item sm={4} xs={12}>
						<Field
							name="price"
							label="Precio"
							component={this.renderTextField}
							type="number"
							required
							inputProps={{
								min: 0,
								step: '.01',
								pattern: '^d*(.d{0,2})?$',
							}}
						/>
					</Grid>
					{this.props.categoryList.map((category, index) => (
						<Grid item xs={12} sm={6} key={'categories_' + index}>
							<Field
								name={'category_' + index}
								component={CategoriesSelect}
								label={_.capitalize(category._id)}
								onChange={category => this.changeCategory(category, index)}
							>
								<MenuItem value="">Todas</MenuItem>
								{category.results.map(cat => (
									<MenuItem key={cat._id} value={cat._id}>
										{_.capitalize(cat.name)}
									</MenuItem>
								))}
							</Field>
						</Grid>
					))}
				</Grid>
				{this.props.specificFields
					.filter(fields => fields.length > 0)
					.map((fields, index) => (
						<Grid container spacing={8} style={{ marginTop: '20px' }} key={'specific_fields_' + index}>
							{fields.map(field => (
								<Grid item xs={12} sm={4} key={field.name} style={{ marginTop: '20px' }}>
									<Field
										name={field.name}
										label={_.capitalize(field.label) || _.capitalize(field.name)}
										component={SpecificFields}
										field_type={field.field_type}
									>
										{field.field_type === 'select' &&
											field.value.map((value, index) => (
												<MenuItem key={index} value={value}>
													{value}
												</MenuItem>
											))}
									</Field>
								</Grid>
							))}
						</Grid>
					))}
				<Grid container spacing={24} style={{ marginTop: '20px' }}>
					<Grid item sm={4} xs={12}>
						<Field
							name="provinces_es"
							component={ProvincesSelect}
							label="Provincias de España"
							all_options_label="Toda España"
							data={this.props.provincesES}
						/>
					</Grid>
					<Grid item sm={4} xs={12}>
						<Field
							name="provinces_pt"
							component={ProvincesSelect}
							label="Distritos de Portugal"
							all_options_label="Toda Portugal"
							data={this.props.provincesPT}
						/>
					</Grid>
					<Grid item sm={4} xs={12}>
						<Field
							name="provinces_and"
							component={ProvincesSelect}
							label="Parroquias de Andorra"
							all_options_label="Toda Andorra"
							data={this.props.provincesAND}
						/>
					</Grid>
					<Grid item xs={12}>
						<Field label="Localidad" name="location" component={this.renderLocation} />
					</Grid>
					<Grid item xs={12}>
						<Field
							label="Imágenes"
							name="images_upload"
							component={this.renderImagesUpload}
							className={classes.dropBox}
						/>
					</Grid>
					<Grid item xs={12}>
						<FieldArray name="images" component={this.renderImages} classes={classes} />
					</Grid>
					<Grid item xs={12}>
						<FormControl component="fieldset" className={classes.formControl}>
							<FormLabel component="legend">Tipo de anunciante</FormLabel>
							<Field
								name="proffessional"
								type="radio"
								value="particular"
								component={this.renderRadio}
								className={classes.group}
								label="Particular"
							/>
							<Field
								name="proffessional"
								type="radio"
								value="profesional"
								component={this.renderRadio}
								className={classes.group}
								label="Profesional"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Field name="published" component={this.renderSwitch} label="Publicado" />
					</Grid>
				</Grid>
				<Button type="submit" size="small" color="primary">
					Enviar
				</Button>
			</form>
		);
	}
}

const validate = values => {
	const errors = {};
	const requiredFields = ['title', 'description'];

	const {
		title,
		description,
		price,
		phone,
		email,
		tag,
		tags,
		category_0,
		provinces_es,
		provinces_pt,
		provinces_and,
	} = values;

	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = 'Campo obligatorio';
		}
	});

	if (title && (title.length < 5 || title.length > 150)) {
		errors.title = 'El título debe contener entre 5 y 150 caracteres.';
	}

	if (description && (description.length < 5 || description.length > 5000)) {
		errors.description = 'La descripción debe contener entre 5 y 5000 caracteres.';
	}

	if (!phone && !email) {
		errors.phone = 'Debes proporcionar al menos una forma de contacto.';
		errors.email = 'Debes proporcionar al menos una forma de contacto.';
	}

	if (phone) {
		//eslint-disable-next-line
		const regExPhoneES = /^(\+34|0034|34)?[\s|\-|\.]?[6|7|8|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
		//eslint-disable-next-line
		const regExPhonePT = /^(\+351|00351|351)?[\s|\-|\.]?[2|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
		//eslint-disable-next-line
		const regExPhoneAD = /^(\+376|00376|376)?[\s|\-|\.]?([0-9][\s|\-|\.]?){6}$/;

		if (
			!(
				regExPhoneES.test(String(phone).toLowerCase()) ||
				regExPhonePT.test(String(phone).toLowerCase()) ||
				regExPhoneAD.test(String(phone).toLowerCase())
			)
		) {
			errors.phone = 'El número de teléfono no es válido.';
		}
	}

	if (email) {
		//eslint-disable-next-line
		const regExMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

		if (!regExMail.test(String(email).toLowerCase())) {
			errors.email = 'El correo electrónico no es válido.';
		}
	}

	if (price && (isNaN(price) || price < 0)) {
		errors.price = 'El precio no es un número válido.';
	}

	if (tag && tag.length > 30) {
		errors.tag = 'La etiqueta no puede exceder los 30 caracteres.';
	}

	if (tags && tags.length > 20) {
		errors.tags = 'El máximo de etiquetas es 20.';
	}

	if (!category_0) {
		errors.category_0 = 'Debe seleccionar una categoría.';
	}

	if (!provinces_es && !provinces_pt && !provinces_and) {
		errors.provinces_es = errors.provinces_pt = errors.provinces_and = 'Debe seleccionar al menos una provincia.';
	}

	return errors;
};

const mapStateToProps = state => ({
	categoryList: state.categories.categoryList,
	specificFields: state.categories.specificFields,
	provincesES: state.locations.provincesES,
	provincesPT: state.locations.provincesPT,
	provincesAND: state.locations.provincesAND,
	locations: state.locations.locations,
});

AdvertForm = reduxForm({ form: 'advertForm', validate })(AdvertForm);

const selector = formValueSelector('advertForm');

AdvertForm = connect(state => {
	const category = selector(state, 'category');
	const tags = selector(state, 'tags');
	const images = selector(state, 'images');

	return {
		category,
		tags,
		images,
	};
})(AdvertForm);

AdvertForm = connect(
	mapStateToProps,
	{
		fetchCategories,
		fetchSubCategories,
		clearSubCategories,
		fetchSpecificFields,
		clearSpecificFields,
		fetchProvinces,
		searchLocations,
	}
)(AdvertForm);

AdvertForm = withStyles(styles)(AdvertForm);

export default AdvertForm;
