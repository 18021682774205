import React from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	withMobileDialog,
	withStyles,
} from '@material-ui/core';
import { ReportProblem, Delete } from '@material-ui/icons';
import { compose } from 'redux';

const styles = theme => ({
	button: {
		margin: theme.spacing.unit,
	},
	icon: {
		marginLeft: theme.spacing.unit,
	},
});

const DeleteModal = props => {
	const { open, handleClose, message, onConfirm, classes } = props;

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				<ReportProblem color="secondary" />
				Confirmar acción
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" autoFocus>
					Cancelar
				</Button>
				<Button variant="contained" color="secondary" className={classes.button} onClick={onConfirm}>
					Confirmar
					<Delete className={classes.icon} />
				</Button>
			</DialogActions>
		</Dialog>
	);
};

DeleteModal.propTypes = {
	open: PropTypes.bool.isRequired,
};

export default compose(
	withMobileDialog(),
	withStyles(styles)
)(DeleteModal);
