import React from 'react';
import { connect } from 'react-redux';
import { fetchUser, editUser } from '../actions';
import {
	CircularProgress,
	withStyles,
	Paper,
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Grid,
} from '@material-ui/core';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { Save } from '@material-ui/icons';

const styles = theme => ({
	root: {
		padding: theme.spacing.unit,
	},
	button: {
		marginRight: theme.spacing.unit,
	},
	icon: {
		marginLeft: theme.spacing.unit,
	},
});

class UserEdit extends React.Component {
	componentDidMount() {
		this.props.fetchUser(this.props.match.params.id);
	}

	submit = values => {
		if (values.phone) {
			//eslint-disable-next-line
			const regExPhoneES = /^(\+34|0034|34)?[\s|\-|\.]?[6|7|8|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
			//eslint-disable-next-line
			const regExPhonePT = /^(\+351|00351|351)?[\s|\-|\.]?[2|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
			//eslint-disable-next-line
			const regExPhoneAD = /^(\+376|00376|376)?[\s|\-|\.]?([0-9][\s|\-|\.]?){6}$/;

			if (
				!(
					regExPhoneES.test(String(values.phone).toLowerCase()) ||
					regExPhonePT.test(String(values.phone).toLowerCase()) ||
					regExPhoneAD.test(String(values.phone).toLowerCase())
				)
			) {
				throw new SubmissionError({
					phone: 'Correo electrónico no válido.',
					_error: 'El teléfono no es válido.',
				});
			}
		}
		this.props.editUser(this.props.initialValues.username, values, user =>
			this.props.history.push(`/user/${user.username}`)
		);
	};

	renderField = ({ input, label, type, disabled, meta: { touched, error } }) => {
		let throwErr = false;
		if (touched && error) throwErr = true;
		return (
			<TextField
				label={label}
				disabled={disabled}
				fullWidth
				margin="normal"
				{...input}
				type={type}
				error={throwErr}
			/>
		);
	};

	renderCheckbox = ({ input, label, name }) => (
		<FormControlLabel control={<Checkbox value={name} />} {...input} label={label} />
	);

	render() {
		const { initialValues, classes, handleSubmit } = this.props;
		if (initialValues) {
			return (
				<Paper className={classes.root}>
					<form onSubmit={handleSubmit(this.submit)}>
						<Grid container>
							<Grid item xs={12}>
								<Field type="text" name="_id" component={this.renderField} label="ID" disabled />
							</Grid>
							<Grid item xs={12}>
								<Field
									type="text"
									name="register"
									component={this.renderField}
									label="Fecha de registro"
									disabled
								/>
							</Grid>
							<Grid item xs={12}>
								<Field type="text" name="name" component={this.renderField} label="Nombre" />
							</Grid>
							<Grid item xs={12}>
								<Field
									type="text"
									name="username"
									component={this.renderField}
									label="Nombre de usuario"
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									type="text"
									name="email"
									component={this.renderField}
									label="Correo electrónico"
								/>
							</Grid>
							<Grid item xs={12}>
								<Field type="tel" name="phone" component={this.renderField} label="Teléfono" />
							</Grid>
							<Grid item xs={12}>
								<Field
									type="checkbox"
									name="proffessional"
									component={this.renderCheckbox}
									label="Profesional"
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									type="checkbox"
									name="is_admin"
									component={this.renderCheckbox}
									label="Administrador"
								/>
							</Grid>
							<Grid item xs={12}>
								{this.props.errorMessage && (
									<FormHelperText error>{this.props.errorMessage}</FormHelperText>
								)}
							</Grid>
							<Button
								variant="contained"
								className={classes.button}
								onClick={() => this.props.history.push(`/user/${this.props.match.params.id}`)}
								color="secondary"
							>
								Cancelar
							</Button>
							<Button type="submit" variant="contained" className={classes.button} color="primary">
								Guardar
								<Save className={classes.icon} />
							</Button>
						</Grid>
					</form>
				</Paper>
			);
		}
		return <CircularProgress color="secondary" />;
	}
}

const mapStateToProps = state => ({
	initialValues: state.users.user,
});

let Form = reduxForm({ form: 'userEdit' })(UserEdit);
Form = connect(
	mapStateToProps,
	{ fetchUser, editUser }
)(Form);
Form = withStyles(styles)(Form);
export default Form;
