import { FETCH_BANNERS, FETCH_BANNER, FILTER_BANNERS, REORDER_BANNERS, UPDATE_ORDER } from '../actions/types';

const INITIAL_STATE = {
	bannerList: [],
	hasNext: false,
	pages: [],
	total: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FILTER_BANNERS:
		case FETCH_BANNERS:
			return {
				bannerList: action.payload.data,
			};
		case UPDATE_ORDER:
		case REORDER_BANNERS:
			return {
				bannerList: action.payload,
			};
		case FETCH_BANNER:
			return {
				...state,
				banner: action.payload,
			};
		default:
			return state;
	}
};
