import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { signin } from '../actions';
import { Paper, Typography, TextField, FormHelperText, Button, withStyles } from '@material-ui/core';
import Recaptcha from 'react-google-recaptcha';

const styles = theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	paper: {
		margin: theme.spacing.unit * 2,
		maxWidth: 500,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit * 2,
	},
	submit: {
		marginTop: theme.spacing.unit * 2,
	},
	image: {
		maxWidth: '100%',
	},
});

class Signin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			captcha: false,
		};
	}

	renderTextField = ({ input, label, type, meta: { touched, error } }) => {
		let throwErr = false;
		if (touched && error) throwErr = true;
		return <TextField label={label} fullWidth required margin="normal" {...input} type={type} error={throwErr} />;
	};

	onSubmit = formProps => {
		//eslint-disable-next-line
		const regExMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (!formProps.email || !regExMail.test(String(formProps.email).toLowerCase())) {
			throw new SubmissionError({
				email: 'Correo electrónico no válido.',
				_error: 'Error al iniciar sesión.',
			});
		} else if (!formProps.password) {
			throw new SubmissionError({
				password: 'Introduzca una contraseña',
				_error: 'Error al iniciar sesión.',
			});
		} else if (!this.state.captcha) {
			throw new SubmissionError({
				_error: 'Debe aceptar el reCaptcha',
			});
		} else {
			this.props.signin(formProps);
		}
	};

	render() {
		const { classes, handleSubmit } = this.props;

		return (
			<Paper className={classes.paper}>
				<img src="https://static.bazaranuncios.com/logo2.png" alt="BazarAnuncios" className={classes.image} />
				<Typography component="h1" variant="h5">
					Iniciar sesión
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit(this.onSubmit)}>
					<Field name="email" component={this.renderTextField} type="email" label="Email" />
					<Field name="password" component={this.renderTextField} type="password" label="Contraseña" />
					{this.props.errorMessage && <FormHelperText error>{this.props.errorMessage}</FormHelperText>}
					<Recaptcha
						sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
						onChange={token => this.setState({ captcha: !!token })}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={!this.state.captcha}
					>
						{this.state.loading ? <i className="fas fa-spinner fa-spin" /> : <span>Iniciar sesión</span>}
					</Button>
				</form>
			</Paper>
		);
	}
}

function mapStateToProps(state) {
	return { errorMessage: state.auth.errorMessage };
}

export default compose(
	connect(
		mapStateToProps,
		{ signin }
	),
	reduxForm({ form: 'signin' }),
	withStyles(styles)
)(Signin);
