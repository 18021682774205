import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import users from './users';
import banners from './banners';
import adverts from './adverts';
import categories from './categories';
import locations from './locations';

export default combineReducers({
	auth,
	users,
	banners,
	adverts,
	categories,
	locations,
	form: formReducer,
});
