import React from 'react';
import { InputLabel, FormControl, FormHelperText, Select, MenuItem, TextField } from '@material-ui/core';
import _ from 'lodash';

const SpecificFields = ({ input, children, label, field_type, name, meta: { touched, error, invalid } }) => {
	switch (field_type) {
		case 'number':
			return (
				<FormControl style={{ width: '100%' }}>
					<TextField
						{...input}
						label={label}
						type="number"
						error={touched && invalid}
						helperText={touched && error}
					/>
				</FormControl>
			);
		case 'boolean':
			return (
				<FormControl style={{ width: '100%' }} error={touched && error ? true : false}>
					<InputLabel htmlFor={name}>{label}</InputLabel>
					<Select fullWidth displayEmpty {...input}>
						<MenuItem style={{ fontStyle: 'italic' }} value={''}>
							{_.capitalize(label)}
						</MenuItem>
						<MenuItem key="Si" value={"true"}>Si</MenuItem>
						<MenuItem key="No" value={"false"}>No</MenuItem>
					</Select>
					{touched && error && <FormHelperText>{touched && error}</FormHelperText>}
				</FormControl>
			);
		case 'select':
			return (
				<FormControl style={{ width: '100%' }} error={touched && error ? true : false}>
					<InputLabel htmlFor={name}>{label}</InputLabel>
					<Select fullWidth displayEmpty {...input}>
						<MenuItem style={{ fontStyle: 'italic' }} value={''}>
							{_.capitalize(label)}
						</MenuItem>
						{children}
					</Select>
					{touched && error && <FormHelperText>{touched && error}</FormHelperText>}
				</FormControl>
			);
		default:
			return null;
	}
};

export default SpecificFields;
