import { FETCH_USERS, FETCH_USER, FILTER_USERS } from '../actions/types';

const INITIAL_STATE = {
	userList: [],
	hasNext: false,
	pages: [],
	total: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FILTER_USERS:
		case FETCH_USERS:
			return {
				userList: action.payload.data,
				hasNext: action.payload.has_next,
				pages: action.payload.pages,
				total: action.payload.total,
			};
		case FETCH_USER:
			return {
				...state,
				user: action.payload,
			};
		default:
			return state;
	}
};
