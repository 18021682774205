import React from 'react';
import { fetchBanners, reorderBanners, updatePositions } from '../actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	Typography,
	ListItemText,
	ListItem,
	List,
	Avatar,
	withStyles,
	Fab,
	CircularProgress,
	Card,
	CardHeader,
	IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Reorder from 'react-reorder';
import { Save, Close, Delete, Create, Search, Add } from '@material-ui/icons';
import DeleteModal from './DeleteModal.jsx';
import api from '../apiConfig';

const styles = theme => ({
	placeholder: {
		height: 56,
		marginBottom: theme.spacing.unit,
		background: 'lightgray',
		borderRadius: 5,
	},
	avatar: {
		background: theme.palette.secondary.main,
	},
	paper: {
		marginBottom: theme.spacing.unit,
		cursor: 'move',
	},
	fab: {
		margin: theme.spacing.unit,
	},
	fabContainer: {
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
	},
	icon: { margin: theme.spacing.unit },
});

class Banners extends React.Component {
	state = {
		changed: false,
		bannerTitle: '',
		bannerId: '',
		showModal: false,
	};

	componentDidMount() {
		this.props.fetchBanners(this.props.match.params.id);
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.props.fetchBanners(this.props.match.params.id);
		}
	}

	renderTitle = () => {
		let title = '';
		switch (Number(this.props.match.params.id)) {
			case 1:
				title = 'Superior';
				break;
			case 2:
				title = 'Superior izquierdo';
				break;
			case 3:
				title = 'Superior derecho';
				break;
			case 4:
				title = 'Inferior izquierdo';
				break;
			case 5:
				title = 'Inferior derecho';
				break;
			default:
				title = '';
		}
		return `Banners (${title})`;
	};

	rowRenderer = ({ key, index }) => {
		const { banners } = this.props;
		return (
			<ListItem key={key}>
				<ListItemText primary={index} secondary={banners[index].title} />
			</ListItem>
		);
	};

	delete = () => {
		api.delete(`/banners/${this.state.bannerId}`).then(() =>
			this.setState({ showModal: false }, () => this.props.fetchBanners(this.props.match.params.id))
		);
	};

	render() {
		const { banners, classes } = this.props;
		if (banners)
			return (
				<div>
					<Typography variant="h5">{this.renderTitle()}</Typography>
					<Link to="/banners/new">
						<Fab variant="extended" color="primary" aria-label="Nuevo" className={classes.fab}>
							<Add className={classes.icon} />
							Nuevo
						</Fab>
					</Link>
					<Reorder
						reorderId="my-list"
						reorderGroup="reorder-group"
						component={List}
						draggedClassName="dragged"
						lock="horizontal"
						holdTime={500}
						touchHoldTime={500}
						mouseHoldTime={200}
						onReorder={(event, previousIndex, nextIndex) => {
							this.setState({ changed: true });
							this.props.reorderBanners(banners, previousIndex, nextIndex);
						}}
						placeholder={<div className={classes.placeholder} />}
					>
						{banners.map((item, index) => (
							<Card className={classes.paper} key={index}>
								<CardHeader
									avatar={
										<Avatar aria-label="Recipe" className={classes.avatar}>
											{item.order}
										</Avatar>
									}
									action={
										<div>
											<Link to={`/banner/${item._id}`}>
												<IconButton>
													<Search />
												</IconButton>
											</Link>
											<Link to={`/banner/${item._id}/edit`}>
												<IconButton>
													<Create />
												</IconButton>
											</Link>
											<IconButton
												onClick={() =>
													this.setState({
														bannerTitle: item.title,
														bannerId: item._id,
														showModal: true,
													})
												}
											>
												<Delete />
											</IconButton>
										</div>
									}
									title={item.title}
									subheader={item.url}
								/>
							</Card>
						))}
					</Reorder>
					<div className={classes.fabContainer}>
						<Fab
							variant="extended"
							color="primary"
							aria-label="Guardar"
							disabled={!this.state.changed}
							className={classes.fab}
							onClick={() => {
								this.setState({ changed: false });
								this.props.updatePositions(this.props.banners);
							}}
						>
							<Save className={classes.icon} />
							Guardar
						</Fab>
						<Fab
							variant="extended"
							color="secondary"
							aria-label="Cancelar"
							disabled={!this.state.changed}
							className={classes.fab}
							onClick={() => {
								this.setState({ changed: false });
								this.props.fetchBanners(this.props.match.params.id);
							}}
						>
							<Close className={classes.icon} />
							Cancelar
						</Fab>
					</div>
					<DeleteModal
						message={`¿Desea eliminar el banner ${
							this.state.bannerTitle
						}? Esta acción no se puede deshacer`}
						handleClose={() => this.setState({ showModal: false })}
						open={this.state.showModal}
						onConfirm={this.delete}
					/>
				</div>
			);
		return <CircularProgress color="secondary" />;
	}
}

const mapStateToProps = state => ({
	banners: state.banners.bannerList,
});

export default compose(
	connect(
		mapStateToProps,
		{ fetchBanners, reorderBanners, updatePositions }
	),
	withStyles(styles)
)(Banners);
