import { FETCH_ADVERT, FETCH_ADVERTS, CREATE_ADVERT} from '../actions/types';

const INITIAL_STATE = {
	advertList: [],
	hasNext: false,
	pages: [],
	total: 0,
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_ADVERT:
			return {
				...state,
				[action.payload.reference]: action.payload,
			};
		case FETCH_ADVERTS:
			return {
				advertList: action.payload.data,
				hasNext: action.payload.has_next,
				pages: action.payload.pages,
				total: action.payload.total,
			};
		case CREATE_ADVERT:
			return {
				...state,
				//[action.payload.id] : action.payload.id,
			};
		default:
			return state;
	}
}
