import axios from 'axios';
import https from 'https';

const token = localStorage.getItem('token');

const config = {
	baseURL: process.env.REACT_APP_API,
	httpsAgent: new https.Agent({
		rejectUnauthorized: false,
	}),
};

if (token) config.headers = { authorization: token };

export default axios.create(config);
