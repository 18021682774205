import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Link } from 'react-router-dom';
import { signout } from './actions';
import {
	AppBar,
	Toolbar,
	IconButton,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
} from '@material-ui/core';
import {
	Menu as MenuIcon,
	ChevronLeft,
	Inbox,
	PowerSettingsNew,
	AccountCircle,
	Collections,
	ExpandLess,
	ExpandMore,
} from '@material-ui/icons';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Signin from './components/Signin.jsx';
import Home from './components/Home.jsx';
import Users from './components/Users.jsx';
import User from './components/User.jsx';
import UserEdit from './components/UserEdit.jsx';
import Banners from './components/Banners.jsx';
import Banner from './components/Banner.jsx';
import BannerEdit from './components/BannerEdit.jsx';
import NewBanner from './components/NewBanner.jsx';
import Adverts from './components/Adverts.jsx';
import NewAdvert from './components/NewAdvert.jsx';
import EditAdvert from './components/EditAdvert.jsx';

const theme = createMuiTheme({
	palette: {
		primary: { main: '#0084c3' },
		secondary: { main: '#e6428f' },
	},
	typography: {
		useNextVariants: true,
	},
});

const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 20,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 8px',
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	toolbar: {
		background: '#FFF',
	},
	login: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	link: {
		textDecoration: 'none',
	},
});

class App extends Component {
	state = { open: true, expanded: false };

	render() {
		const { classes } = this.props;
		let { open, expanded } = this.state;

		return (
			<MuiThemeProvider theme={theme}>
				<CssBaseline />

				{this.props.auth ? (
					<div className={classes.root}>
						<AppBar
							position="fixed"
							className={classNames(classes.appBar, {
								[classes.appBarShift]: open,
							})}
						>
							<Toolbar disableGutters={!open} className={classes.toolbar}>
								<IconButton
									color="primary"
									aria-label="Open drawer"
									onClick={() => this.setState({ open: !open })}
									className={classNames(classes.menuButton, open && classes.hide)}
								>
									<MenuIcon />
								</IconButton>
								<img
									src="https://static.bazaranuncios.com/logo2.png"
									alt="BazarAnuncios"
									className={classes.image}
								/>
							</Toolbar>
						</AppBar>
						<Drawer
							className={classes.drawer}
							variant="persistent"
							anchor="left"
							open={open}
							classes={{
								paper: classes.drawerPaper,
							}}
						>
							<div className={classes.drawerHeader}>
								<IconButton onClick={() => this.setState({ open: !open })}>
									<ChevronLeft />
								</IconButton>
							</div>
							<Divider />
							<List>
								<ListItem>
									<ListItemIcon>
										<AccountCircle />
									</ListItemIcon>
									<ListItemText primary={this.props.user.name} />
								</ListItem>
							</List>
							<Divider />
							<List>
								<Link className={classes.link} to="/">
									<ListItem button>
										<ListItemIcon>
											<Inbox />
										</ListItemIcon>
										<ListItemText primary="Inicio" />
									</ListItem>
								</Link>
								<Link className={classes.link} to="/users">
									<ListItem button>
										<ListItemIcon>
											<Inbox />
										</ListItemIcon>
										<ListItemText primary="Usuarios" />
									</ListItem>
								</Link>
								<Link className={classes.link} to="/adverts">
									<ListItem button>
										<ListItemIcon>
											<Inbox />
										</ListItemIcon>
										<ListItemText primary="Anuncios" />
									</ListItem>
								</Link>
								<ListItem button onClick={() => this.setState({ expanded: !expanded })}>
									<ListItemIcon>{expanded ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
									<ListItemText primary="Banners" />
								</ListItem>
								<Collapse in={expanded} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										<Link className={classes.link} to="/banners/position/1">
											<ListItem button className={classes.nested}>
												<ListItemIcon>
													<Collections />
												</ListItemIcon>
												<ListItemText inset primary="Superior" />
											</ListItem>
										</Link>
										<Link className={classes.link} to="/banners/position/2">
											<ListItem button className={classes.nested}>
												<ListItemIcon>
													<Collections />
												</ListItemIcon>
												<ListItemText inset primary="Superior izquierdo" />
											</ListItem>
										</Link>
										<Link className={classes.link} to="/banners/position/3">
											<ListItem button className={classes.nested}>
												<ListItemIcon>
													<Collections />
												</ListItemIcon>
												<ListItemText inset primary="Superior derecho" />
											</ListItem>
										</Link>
										<Link className={classes.link} to="/banners/position/4">
											<ListItem button className={classes.nested}>
												<ListItemIcon>
													<Collections />
												</ListItemIcon>
												<ListItemText inset primary="Inferior izquierdo" />
											</ListItem>
										</Link>
										<Link className={classes.link} to="/banners/position/5">
											<ListItem button className={classes.nested}>
												<ListItemIcon>
													<Collections />
												</ListItemIcon>
												<ListItemText inset primary="Inferior derecho" />
											</ListItem>
										</Link>
									</List>
								</Collapse>
							</List>
							<Divider />
							<List>
								<ListItem button onClick={this.props.signout}>
									<ListItemIcon>
										<PowerSettingsNew />
									</ListItemIcon>
									<ListItemText primary="Cerrar sesión" />
								</ListItem>
							</List>
						</Drawer>
						<main
							className={classNames(classes.content, {
								[classes.contentShift]: open,
							})}
						>
							<div className={classes.drawerHeader} />
							<Route path="/" exact component={Home} />
							<Route path="/users" component={Users} />
							<Route path="/user/:id" exact component={User} />
							<Route path="/user/:id/edit" component={UserEdit} />
							<Route path="/banners/position/:id" exact component={Banners} />
							<Route path="/banners/new" exact component={NewBanner} />
							<Route path="/banner/:id" exact component={Banner} />
							<Route path="/banner/:id/edit" component={BannerEdit} />
							<Route path="/adverts" component={Adverts} />
							<Route path="/advert/new" component={NewAdvert} />
							<Route path="/advert/edit/:id" exact component={EditAdvert} />
						</main>
					</div>
				) : (
					<div className={classes.login}>
						<Signin />
					</div>
				)}
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = state => ({ auth: state.auth.authenticated, user: state.auth.user });

export default compose(
	connect(
		mapStateToProps,
		{ signout }
	),
	withStyles(styles)
)(App);
