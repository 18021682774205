import api from '../apiConfig';

import {
	AUTH_USER,
	AUTH_ERROR,
	FETCH_USERS,
	DELETE_USER,
	FETCH_USER,
	FILTER_USERS,
	EDIT_USER,
	FETCH_BANNERS,
	FETCH_BANNER,
	FILTER_BANNERS,
	REORDER_BANNERS,
	UPDATE_ORDER,
	FETCH_ADVERT,
	FETCH_ADVERTS,
	CREATE_ADVERT,
	EDIT_ADVERT,
	DELETE_ADVERT,
	FETCH_CATEGORIES,
	FETCH_SUB_CATEGORIES,
	CLEAR_SUB_CATEGORIES,
	FETCH_SPECIFIC_FIELDS,
	CLEAR_SPECIFIC_FIELDS,
	FETCH_PROVINCES,
	SEARCH_LOCATION,
} from './types';

import { reorder } from 'react-reorder';

export const signin = (formProps, callback) => async dispatch => {
	try {
		const response = await api.post('/admin/auth/signin', formProps);

		if (response.data && response.data.token && response.data.user && response.data.user.is_admin) {
			dispatch({ type: AUTH_USER, payload: response.data });
			localStorage.setItem('token', response.data.token);
			localStorage.setItem('user', JSON.stringify(response.data.user));
		} else {
			dispatch({ type: AUTH_ERROR, payload: 'Error al iniciar sesión' });
		}
		callback();
	} catch (e) {
		dispatch({ type: AUTH_ERROR, payload: 'Error al iniciar sesión' });
	}
};

export const signout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('user');

	return {
		type: AUTH_USER,
		payload: '',
	};
};

export const fetchUsers = (limit = 10, page = 1) => async dispatch => {
	const response = await api.get(`/users?page=${page}&limit=${limit}`);

	dispatch({ type: FETCH_USERS, payload: response.data });
};

export const deleteUser = (id, callback) => async dispatch => {
	await api.delete(`/users/${id}`).then(callback);

	dispatch({ type: DELETE_USER, payload: '' });
};

export const fetchUser = id => async dispatch => {
	const response = await api.get(`/users/${id}`);

	dispatch({ type: FETCH_USER, payload: response.data });
};

export const filterUsers = (text, limit = 10, page = 1) => async dispatch => {
	const response = await api.post(`/users/search?page=${page}&limit=${limit}`, { text });

	dispatch({ type: FILTER_USERS, payload: response.data });
};

export const editUser = (id, props, callback) => async dispatch => {
	const response = await api.patch(`/users/${id}`, { props });
	callback(response.data);
	dispatch({ type: EDIT_USER, payload: '' });
};

export const fetchBanners = (id, limit = 10, page = 1) => async dispatch => {
	const response = await api.get(`/banners/position/${id}?page=${page}&limit=${limit}&full=true`);

	dispatch({ type: FETCH_BANNERS, payload: response.data });
};

export const reorderBanners = (list, previousIndex, nextIndex) => dispatch => {
	const payload = reorder(list, previousIndex, nextIndex).map((l, index) => Object.assign(l, { order: index + 1 }));
	dispatch({ type: REORDER_BANNERS, payload });
};

export const fetchBanner = id => async dispatch => {
	const response = await api.get(`/banners/${id}`);

	dispatch({ type: FETCH_BANNER, payload: response.data });
};

export const updatePositions = banners => async dispatch => {
	const response = await api.patch(`/banners/positions`, { banners });

	dispatch({ type: UPDATE_ORDER, payload: response.data.banners });
};

export const filterBanners = (text, limit = 10, page = 1) => async dispatch => {
	const response = await api.post(`/users/search?page=${page}&limit=${limit}`, { text });

	dispatch({ type: FILTER_BANNERS, payload: response.data });
};

export const fetchAdvert = id => async dispatch => {
	const response = await api.get(`/admin/adverts/${id}`);

	dispatch({ type: FETCH_ADVERT, payload: response.data });
};

export const fetchAdverts = (limit = 10, page = 1, values = {}) => async dispatch => {
	const response = await api.post(`/admin/adverts?page=${page}&limit=${limit}`, values);

	dispatch({ type: FETCH_ADVERTS, payload: response.data });
};

export const createAdvert = (form_data, callback) => async dispatch => {
	const response = await api.post('/adverts', form_data).then(callback);

	dispatch({ type: CREATE_ADVERT, payload: response });
};

export const editAdvert = (id, form_data, callback) => async dispatch => {
	const response = await api.patch(`/adverts/${id}`, form_data).then(callback);

	callback(response);

	dispatch({ type: EDIT_ADVERT, payload: '' });
};

export const deleteAdvert = (id, callback) => async dispatch => {
	const response = await api.delete(`/adverts/${id}`).then(callback);

	callback(response);

	dispatch({ type: DELETE_ADVERT, payload: '' });
};

export const fetchCategories = () => async dispatch => {
	const response = await api.get('/categories?root=true');

	dispatch({ type: FETCH_CATEGORIES, payload: response.data[0] });
};

export const fetchSubCategories = id => async dispatch => {
	const response = await api.get(`/categories?parent=${id}`);

	dispatch({ type: FETCH_SUB_CATEGORIES, payload: response.data[0] });
};

export const fetchSpecificFields = specificFields => async dispatch => {
	dispatch({ type: FETCH_SPECIFIC_FIELDS, payload: specificFields });
};

export const clearSubCategories = categoryList => async dispatch => {
	dispatch({ type: CLEAR_SUB_CATEGORIES, payload: categoryList });
};


export const clearSpecificFields = () => {
	return {
		type: CLEAR_SPECIFIC_FIELDS,
		payload: [],
	};
};

export const fetchProvinces = () => async dispatch => {
	const response = await api.get('/provinces');

	dispatch({ type: FETCH_PROVINCES, payload: response.data });
};

export const searchLocations = query => async dispatch => {
	const response = await api.post('/locations/search', { query });

	dispatch({ type: SEARCH_LOCATION, payload: response.data });
};
