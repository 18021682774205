import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
	TableFooter,
	TablePagination,
} from '@material-ui/core';
import { Search, Delete, Create } from '@material-ui/icons';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchUsers, deleteUser, filterUsers } from '../actions';
import DeleteModal from './DeleteModal.jsx';
import Searchbar from './Searchbar.jsx';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class SimpleTable extends React.Component {
	state = {
		openModal: false,
		deleteId: '',
		deleteName: '',
		page: 0,
		rowsPerPage: 10,
		filtered: false,
		filterText: '',
	};

	componentDidMount() {
		this.props.fetchUsers();
	}

	onChangeRowsPerPage = rowsPerPage => {
		this.setState({ rowsPerPage }, () =>
			this.state.filtered
				? this.props.filterUsers(this.state.filterText, this.state.rowsPerPage, this.state.page + 1)
				: this.props.fetchUsers(this.state.rowsPerPage, this.state.page + 1)
		);
	};

	handleChangePage = (event, page) => {
		this.setState({ page }, () =>
			this.state.filtered
				? this.props.filterUsers(this.state.filterText, this.state.rowsPerPage, this.state.page + 1)
				: this.props.fetchUsers(this.state.rowsPerPage, this.state.page + 1)
		);
	};

	submit = ({ text }) => {
		this.setState({ filtered: true, filterText: text });
		this.props.filterUsers(text);
	};

	dismiss = () => {
		this.setState({ filtered: false, filterText: '' });
		this.props.fetchUsers(this.state.rowsPerPage, this.state.page + 1);
	};

	render() {
		const { classes, userList, total } = this.props;
		const { openModal, deleteName, deleteId, page, rowsPerPage } = this.state;

		return (
			<div>
				<Typography variant="h5">Usuarios</Typography>
				<Searchbar onSubmit={this.submit} dismiss={this.dismiss} />
				<Paper className={classes.root}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Nombre</TableCell>
								<TableCell align="right">Nombre de usuario</TableCell>
								<TableCell align="right">Email</TableCell>
								<TableCell align="right">Teléfono</TableCell>
								<TableCell align="right">Fecha de registro</TableCell>
								<TableCell align="right">Acciones</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{userList.map(user => (
								<TableRow key={user._id}>
									<TableCell component="th" scope="row">
										{user.name}
									</TableCell>
									<TableCell align="right">{user.username}</TableCell>
									<TableCell align="right">{user.email}</TableCell>
									<TableCell align="right">{user.phone || ''}</TableCell>
									<TableCell align="right">{new Date(user.register).toLocaleString()}</TableCell>
									<TableCell align="right">
										<div>
											<Link to={`/user/${user.username}/edit`}>
												<IconButton color="primary">
													<Create />
												</IconButton>
											</Link>
											<Link to={`/user/${user.username}`}>
												<IconButton color="primary">
													<Search />
												</IconButton>
											</Link>
											<IconButton
												onClick={() =>
													this.setState({
														openModal: true,
														deleteId: user._id,
														deleteName: user.name,
													})
												}
												color="secondary"
											>
												<Delete />
											</IconButton>
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25, 50]}
									colSpan={6}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									labelRowsPerPage="Ver: "
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={event => this.onChangeRowsPerPage(event.target.value)}
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
								/>
							</TableRow>
						</TableFooter>
					</Table>
				</Paper>
				<DeleteModal
					open={openModal}
					handleClose={() => this.setState({ openModal: false })}
					message={`¿Desea eliminar al usuario ${deleteName}? Esta acción no se puede deshacer.`}
					onConfirm={() => {
						this.setState({ openModal: false });
						this.props.deleteUser(deleteId, () =>
							this.props.fetchUsers(this.state.rowsPerPage, this.state.page + 1)
						);
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	userList: state.users.userList,
	hasNext: state.users.has_next,
	pages: state.users.pages,
	total: state.users.total,
});

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		{ fetchUsers, deleteUser, filterUsers }
	)
)(SimpleTable);
