import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchAdvert, editAdvert } from '../actions';

import AdvertForm from './AdvertForm';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		zIndex: 1,
		padding: theme.spacing.unit,
	},
	table: {
		minWidth: 700,
	},
});

class EditAdvert extends React.Component {
	state = {
		initial: {},
		loading: true,
	};

	async componentDidMount() {
		this.props.fetchAdvert(this.props.match.params.id).then(async () => {
			const initial = await renderInitialValues(this.props.advert);

			this.setState({ initial, loading: false });
		});
	}

	send = values => {
		var form_data = new FormData();

		for (var key in values) {
			if (key === 'images') {
				values['images'].forEach(i => {
					if (i instanceof File) {
						form_data.append('images', i);
					} else {
						form_data.append('old_images', i.preview);
					}
				});
			} else if (key === 'specific_fields') {
				const fields = JSON.stringify(values['specific_fields']);
				form_data.append(key, fields);
			} else {
				if (values[key]) {
					form_data.append(key, values[key]);
				}
			}
		}

		this.props.editAdvert(this.props.match.params.id, form_data, () => {
			this.props.fetchAdvert(this.props.match.params.id);
			this.props.history.push('/adverts');
		});
	};

	render() {
		const { classes } = this.props;

		if (this.state.loading) {
			return <div>Cargando ...</div>;
		}

		return (
			<div>
				<Typography variant="h5">Editar Anuncio</Typography>
				<Paper className={classes.root}>
					<AdvertForm initialValues={this.state.initial} onConfirm={this.send} />
				</Paper>
			</div>
		);
	}
}

const renderInitialValues = async values => {
	let initial = {};

	for (let key in values) {
		if (key === 'category') {
			let category = values[key]._id;
			initial['categories'] = [];

			if (values[key].ancestors.length > 0) {
				let i = 0;

				await Promise.all(
					values[key].ancestors.map(async (category, index) => {
						initial['category_' + index] = category;
						initial['categories'].push({ category });
						i++;
					})
				);

				initial['category_' + i] = category;
				initial['categories'].push({ category });
			} else {
				initial['category_0'] = category;
			}
		} else if (key === 'provinces') {
			_.forEach(values[key], function(province) {
				let prov = { label: province.name, value: province._id };

				if (initial[key + '_' + province.country]) {
					initial[key + '_' + province.country].push(prov);
				} else {
					initial[key + '_' + province.country] = [prov];
				}
			});
		} else if (key === 'specific_fields') {
			_.forEach(values[key], function(field) {
				initial[field.name] = field.selectedValue;
			});
		} else if (key === 'images') {
			_.map(values[key], function(image) {
				if (initial[key]) {
					initial[key].push({
						preview: image,
					});
				} else {
					initial[key] = [
						{
							preview: image,
						},
					];
				}
			});
		} else if (key === 'location') {
			initial[key] = { label: values[key].name, value: values[key]._id };
		} else if (key === 'proffessional'){
			if (values[key] === true) initial[key] = 'profesional';
			else if (values[key] === false) initial[key] = 'particular';
		} else {
			initial[key] = values[key];
		}
	}

	if (initial.provinces_es && _.size(initial.provinces_es) === 52) {
		initial.provinces_es = [{ label: 'Toda España', value: '*' }];
	}

	if (initial.provinces_pt && _.size(initial.provinces_pt) === 20) {
		initial.provinces_pt = [{ label: 'Toda Portugal', value: '*' }];
	}

	if (initial.provinces_and && _.size(initial.provinces_and) === 7) {
		initial.provinces_and = [{ label: 'Toda Andorra', value: '*' }];
	}

	return initial;
};

const mapStateToProps = (state, ownProps) => {
	return { advert: state.adverts[ownProps.match.params.id] };
};

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		{ fetchAdvert, editAdvert }
	)
)(EditAdvert);
