import React from 'react';
import {
	TextField,
	Button,
	withStyles,
	MenuItem,
	InputLabel,
	FormControl,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	ExpansionPanelActions,
	Typography,
	Divider,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { reduxForm, Field, reset, change } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCategories, fetchSubCategories, clearSubCategories, fetchProvinces } from '../actions';

import _ from 'lodash';

import ProvincesSelect from './ProvincesSelect';
import CategoriesSelect from './CategoriesSelect';

const styles = theme => ({
	root: {
		width: '100%',
	},

	heading: {
		fontSize: theme.typography.pxToRem(15),
	},
	details: {
		alignItems: 'center',
	},
	column: {
		flexBasis: '33.33%',
		padding: '4px',
	},
});

class SearchForm extends React.Component {
	state = {
		loading: false,
	};

	componentDidMount() {
		this.props.fetchCategories();
		this.props.fetchProvinces();
	}

	onSubmit = formValues => {
		const { text, provinces_es, provinces_pt, provinces_and } = formValues;

		let provincesES = _.map(provinces_es, 'value');
		let provincesPT = _.map(provinces_pt, 'value');
		let provincesAND = _.map(provinces_and, 'value');

		if (provincesES[0] === '*') {
			provincesES = _.map(this.props.provincesES, '_id');
		}

		if (provincesPT[0] === '*') {
			provincesPT = _.map(this.props.provincesPT, '_id');
		}

		if (provincesAND[0] === '*') {
			provincesAND = _.map(this.props.provincesAND, '_id');
		}

		const provinces = [...provincesES, ...provincesPT, ...provincesAND];

		let category = '';

		for (var key in formValues) {
			if (key.match('^category_*') && formValues[key] !== '') {
				category = formValues[key];
			}
		}

		const values = {
			text,
			category,
			provinces,
		};

		this.props.onSubmit(values);
	};

	async changeCategory(category, index) {
		let categoryList = this.props.categoryList.slice(0, index + 1);

		let i = 0;

		for (i = index; i < this.props.categoryList.length; i++) {
			this.props.dispatch(change('searchForm', 'category_' + i, ''));
		}

		await this.props.clearSubCategories(categoryList);

		if (category.target.value) await this.props.fetchSubCategories(category.target.value);
	}

	removeForm = () => {
		this.props.clearSubCategories([]);
		this.props.fetchCategories();
		this.props.dispatch(reset('searchForm'));
		this.props.onSubmit({});
	};

	renderTextField = ({ input, label, type }) => {
		return (
			<FormControl style={{ width: '100%' }}>
				<InputLabel htmlFor="text" shrink>
					{label}
				</InputLabel>
				<TextField fullWidth margin="normal" {...input} type={type} style={{ marginBottom: '0px' }} />
			</FormControl>
		);
	};

	render() {
		const { classes } = this.props;

		return (
			<form id="searchForm" onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<div className={classes.root}>
					<ExpansionPanel>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
							<div className={classes.column}>
								<Typography className={classes.heading}>Filtros</Typography>
							</div>
						</ExpansionPanelSummary>
						<Divider />
						<ExpansionPanelDetails className={classes.details}>
							<div className={classes.column}>
								<Field
									name="text"
									component={this.renderTextField}
									type="text"
									label="Texto de filtrado"
								/>
							</div>
						</ExpansionPanelDetails>
						<ExpansionPanelDetails className={classes.details}>
							{this.props.categoryList.map((category, index) => (
								<div className={classes.column} key={'categories_' + index}>
									<Field
										name={'category_' + index}
										component={CategoriesSelect}
										label={_.capitalize(category._id)}
										onChange={category => this.changeCategory(category, index)}
									>
										<MenuItem value="">Todas</MenuItem>
										{category.results.map(cat => (
											<MenuItem key={cat._id} value={cat._id}>
												{_.capitalize(cat.name)}
											</MenuItem>
										))}
									</Field>
								</div>
							))}
						</ExpansionPanelDetails>
						<ExpansionPanelDetails className={classes.details}>
							<div className={classes.column}>
								<Field
									name="provinces_es"
									component={ProvincesSelect}
									label="Provincias de España"
									all_options_label="Toda España"
									data={this.props.provincesES}
								/>
							</div>
							<div className={classes.column}>
								<Field
									name="provinces_pt"
									component={ProvincesSelect}
									label="Distritos de Portugal"
									all_options_label="Toda Portugal"
									data={this.props.provincesPT}
								/>
							</div>
							<div className={classes.column}>
								<Field
									name="provinces_and"
									component={ProvincesSelect}
									label="Parroquias de Andorra"
									all_options_label="Toda Andorra"
									data={this.props.provincesAND}
								/>
							</div>
						</ExpansionPanelDetails>
						<Divider />
						<ExpansionPanelActions>
							<Button size="small" onClick={this.removeForm}>
								Restablecer
							</Button>
							<Button type="submit" size="small" color="primary">
								Filtar
							</Button>
						</ExpansionPanelActions>
					</ExpansionPanel>
				</div>
			</form>
		);
	}
}

const mapStateToProps = state => ({
	categoryList: state.categories.categoryList,
	provincesES: state.locations.provincesES,
	provincesPT: state.locations.provincesPT,
	provincesAND: state.locations.provincesAND,
});

export default compose(
	connect(
		mapStateToProps,
		{ fetchCategories, fetchSubCategories, clearSubCategories, fetchProvinces }
	),
	reduxForm({ form: 'searchForm' }),
	withStyles(styles)
)(SearchForm);
