import { FETCH_PROVINCES, SEARCH_LOCATION } from '../actions/types';

const INITIAL_STATE = {
    provincesES: [],
    provincesPT: [],
	provincesAND: [],
	locations:[],
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_PROVINCES:
			return {
                provincesES: action.payload.es,
                provincesPT: action.payload.pt,
                provincesAND: action.payload.and,
			};
		case SEARCH_LOCATION:
			return {
				...state,
				locations: action.payload,
			}		
		default:
			return state;
	}
}
