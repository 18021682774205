import React from 'react';
import { Paper, InputBase, IconButton, Divider, withStyles } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import { compose } from 'redux';
import { reduxForm, Field, reset } from 'redux-form';

const styles = theme => ({
	search: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
	},
	form: { display: 'flex', flex: 1 },
	iconButton: {
		padding: theme.spacing.unit,
	},
});

class SearchBar extends React.Component {
	renderField = ({ input, placeholder, type, meta: { touched, error } }) => {
		let throwErr = false;
		if (touched && error) throwErr = true;
		return <InputBase placeholder={placeholder} fullWidth required {...input} type={type} error={throwErr} />;
	};

	render() {
		const { classes, onSubmit, handleSubmit, dismiss } = this.props;

		return (
			<Paper className={classes.search} elevation={1}>
				<form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
					<Field name="text" component={this.renderField} type="text" placeholder="Buscar" />
					<IconButton className={classes.iconButton} aria-label="Buscar" type="submit">
						<Search />
					</IconButton>
					<Divider className={classes.divider} />
					<IconButton
						color="secondary"
						className={classes.iconButton}
						onClick={() => {
							this.props.dispatch(reset('filterUsers'));
							dismiss();
						}}
						aria-label="Directions"
					>
						<Close />
					</IconButton>
				</form>
			</Paper>
		);
	}
}

export default compose(
	withStyles(styles),
	reduxForm({ form: 'filterUsers' })
)(SearchBar);
