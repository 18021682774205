import {
	FETCH_CATEGORIES,
	FETCH_SUB_CATEGORIES,
	CLEAR_SUB_CATEGORIES,
	FETCH_SPECIFIC_FIELDS,
	CLEAR_SPECIFIC_FIELDS,
} from '../actions/types';

import _ from 'lodash';

const INITIAL_STATE = {
	categoryList: [],
	subCategoryList: [],
	specificFields: [],
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case FETCH_CATEGORIES:
			return {
				...state,
				categoryList: [action.payload],
			};
		case FETCH_SUB_CATEGORIES:
			return {
				...state,
				categoryList: _.isEmpty(action.payload)
					? [...state.categoryList]
					: [...state.categoryList, { ...action.payload }],
			};
		case CLEAR_SUB_CATEGORIES:
			return {
				...state,
				categoryList: action.payload,
			};
		case FETCH_SPECIFIC_FIELDS:
			return {
				...state,
				specificFields: action.payload,
			};
		case CLEAR_SPECIFIC_FIELDS:
			return {
				...state,
				specificFields: action.payload,
			};

		default:
			return state;
	}
}
